<template>
  <div class="home">
    <div class="box">
      <div class="home_title fs-46">权益服务</div>
      <div class="tab_bar mt-50 g-flex-jb">
        <div class="item">
          <img
            @click="jump('2')"
            src="../assets/images/home/Android.png"
            alt=""
          />
        </div>
        <div class="item">
          <img
            @click="jump('1')"
            src="../assets/images/home/apple.png"
            alt=""
          />
        </div>
      </div>
      <!-- 搜索，扫码 -->
      <div class="input_btn">
        <van-search
          v-model="SerachValue"
          placeholder="搜索电话号码或扫描IMEI码"
          @search="onSearch"
          show-action
          shape="round"
        >
          <template #right-icon>
            <div class="img_seach g-flex-jb">
              <!-- <img @click="smClick" src="../assets/images/home/icon_sm.png" alt=""> -->
              <scan-code
                @change="onSearch"
                :just-icon="true"
                v-model="SerachValue"
              ></scan-code>
            </div>
          </template>
          <template #action>
            <div @click="onSearch" class="search-btn">搜索</div>
          </template>
        </van-search>
      </div>

      <!-- 订单类型，日期筛选下拉 -->
      <div class="order_filter g-flex-aa">
        <div
          class="selec_ g-flex-ac"
          style="white-space: nowrap"
          @click="selectType"
        >
          <img src="../assets/images/home/icon_ddlx.png" alt="" />
          <span class="fs-28 mr-20 ml-10">{{ currentType.label }}</span>
          <div class="g-flex-ac" v-if="orderShow">
            <img src="../assets/images/home/icon_sjt_.png" alt="" />
          </div>
          <div class="g-flex-ac" v-else>
            <img src="../assets/images/home/icon_xjt_.png" alt="" />
          </div>
        </div>
        <div
          class="selec_ g-flex-ac ml-30"
          style="white-space: nowrap"
          @click="startTimeshow = true"
        >
          <img src="../assets/images/home/icon_rq.png" alt="" />
          <span class="fs-28 mr-20 ml-10" v-if="dateValue">{{
            dateValue
          }}</span>
          <span class="fs-28 mr-20 ml-10" v-else>日期</span>
          <template v-if="!dateValue">
            <div class="g-flex-ac" v-if="startTimeshow">
              <img src="../assets/images/home/icon_sjt_.png" alt="" />
            </div>
            <div class="g-flex-ac" v-else>
              <img src="../assets/images/home/icon_xjt_.png" alt="" />
            </div>
          </template>
          <div class="g-flex-ac" v-else>
            <img
              src="../assets/images/home/icon_.png"
              @click.stop="
                dateValue = '';
                params.date = [];
                onLoad(1);
              "
              alt=""
            />
          </div>
        </div>
      </div>
      <!-- 订单内容 -->
      <div class="Home_order" v-if="HomeList.length > 0">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="item in HomeList" :key="item.id">
            <div class="tit_top g-flex-ac">
              <div class="ml-20 date">{{ item.day }}</div>
              <div class="mounth ml-10">{{ item.month }}</div>
              <div class="right ml-40">
                投保共<span style="color:#444CF0">{{ item.sum }}</span
                >单
              </div>
            </div>

            <div class="orderItem" v-for="iitm in item.list" :key="iitm.id">
              <div @click="ItemDeteil(iitm)">
                <div class="item_top g-flex-jb g-flex-ac">
                  <div
                    class="ml-30 item_tit_le fs-32"
                    style="color: #FDC95B;"
                    v-if="iitm.exa_status === 0"
                  >
                    审核中
                  </div>
                  <div
                    class="ml-30 item_tit_le fs-32"
                    style="color: #444CF0;"
                    v-else-if="iitm.exa_status === 1"
                  >
                    审核已通过
                  </div>
                  <div
                    class="ml-30 item_tit_le fs-32"
                    style="color: #FB7320;"
                    v-else-if="iitm.exa_status === 2"
                  >
                    审核未通过
                  </div>
                  <div
                    class="ml-30 item_tit_le fs-32"
                    style="color: #666666;"
                    v-else-if="iitm.exa_status === 3"
                  >
                    已失效
                  </div>
                  <div
                    class="ml-30 item_tit_le fs-32"
                    style="color: #666666;"
                    v-else
                  >
                    完成
                  </div>
                  <div class="item_tit_ri fs-28" v-if="iitm.order_type === 1">
                    权益单
                  </div>
                  <div class="item_tit_ri fs-28" v-else>理赔单</div>
                </div>
                <div class="mt-30 g-flex-ac">
                  <div class="ml-30 fs-28 left_wid" style="color:#999">
                    权益类型:
                  </div>
                  <div class="fs-28" style="color:#333">
                    {{ iitm.instype_name }}
                  </div>
                </div>
                <div class="mt-30 g-flex-ac">
                  <div class="ml-30 fs-28 left_wid" style="color:#999">
                    参保人:
                  </div>
                  <div class="fs-28" style="color:#333">{{ iitm.name }}</div>
                </div>
                <div class="mt-30 g-flex-ac">
                  <div class="ml-30 fs-28 left_wid" style="color:#999">
                    联系电话:
                  </div>
                  <div class="fs-28" style="color:#333">{{ iitm.phone }}</div>
                </div>
                <div class="mt-30 g-flex-ac">
                  <div class="ml-30 fs-28 left_wid" style="color:#999">
                    接待人员:
                  </div>
                  <div class="fs-28" style="color:#333">
                    {{ iitm.shop_member_name }}
                  </div>
                </div>
                <div class="bot_img g-flex-dft">
                  <div class="img_left">
                    <img :src="iitm.brand_img" alt="" />
                  </div>
                  <div class="img_right fs-28 mt-20 ml-20">
                    {{ iitm.brand }} {{ iitm.model_name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
      <div v-else class="Home_order_No g-flex-ac">
        <div class="order_No m-at">
          <img src="../assets/images/home/zu8.png" alt="" />
          <span class="text fs-24">暂无内容</span>
        </div>
      </div>
    </div>

    <!-- 日历弹窗 -->
    <van-popup round v-model="startTimeshow" position="bottom" :overlay="true">
      <van-calendar
        title="选择日期"
        :poppable="false"
        :style="{ height: '500px' }"
        :min-date="minDate"
        type="range"
        @confirm="onConfirm"
      />
    </van-popup>
    <!-- 订单类型选择弹窗 -->
    <van-popup
      v-model="orderShow"
      round
      position="bottom"
      :style="{ height: '40%' }"
    >
      <div class="order_title fs-36">订单类型</div>
      <div class="line"></div>
      <div class="order_box g-flex-aa">
        <div
          class="item fs-28"
          :class="item.key === currentTypeCopy.key ? 'after' : 'berfore'"
          v-for="item in types"
          :key="item.key"
          @click="clickItem(item)"
        >
          {{ item.label }}
        </div>
      </div>
      <div class="order_btn g-flex-jb g-flex-ac">
        <div class="cancel_b btn fs-30 ml-40" @click="orderShow = false">
          取消
        </div>
        <div class="submit_b btn fs-30 mr-40" @click="submitOrder">确定</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      loading: false,
      finished: false,
      params: {
        page: 1, //页码
        limit: 10, //每页条数
        type: "1", //选中订单类型的值
        date: [], //时间
        keyword: "", //搜索框值
      },
      SerachValue: "", //搜索框
      HomeList: [], //列表数据
      startTimeshow: false, //时间弹窗
      orderShow: false, //订单选择弹窗
      minDate: new Date(2010, 0, 1), //时间日历弹窗范围（最小时间显示）
      //订单类型数组
      types: [
        { key: "2", label: "理赔订单" },
        { key: "1", label: "权益订单" },
      ],
      currentType: { key: "1", label: "权益订单" },
      currentTypeCopy: {},
      dateValue: "", //时间选择回显
      orderType: "权益订单",
    };
  },
  mounted() {
    let type = sessionStorage.getItem("homeOrderType");
    if (type) {
      this.currentType = JSON.parse(type);
    }
    this.onLoad(1);
  },
  methods: {
    ItemDeteil(val) {
      if (val.order_type === 1) {
        this.$router.push({
          path: "/RightApply/detailsApply",
          query: { id: val.order_id },
        });
      } else if (val.order_type === 2) {
        this.$router.push({
          name: "ClaimSettlementDetail",
          params: { id: val.claims_id },
        });
      }
    },
    onLoad(val) {
      if (val) {
        this.params.page = val;
        this.HomeList = [];
      }
      this.params.type = this.currentType.key;
      this.$http
        .HomeOrderList(this.params)
        .then((res) => {
          console.log(res, "onload");
          this.loading = false;
          this.finished = res.count.page >= res.count.pageAll;
          // this.HomeList = res.data
          this.HomeList.push(...res.data);
          this.params.page++;
          console.log(this.finished, this.params, "finished");
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    //搜索
    onSearch(val) {
      console.log(val, "扫码搜索");
      this.params.keyword = this.SerachValue;
      this.onLoad(1);
    },
    //扫码
    // smClick(val){
    //   this.$router.push('/orderHome/index')
    //   console.log(val,'123123')
    // },
    //时间选择确认
    onConfirm(val) {
      let starTime = Date.parse(new Date(val[0])) / 1000;
      let endTime = Date.parse(new Date(val[1])) / 1000;
      this.params.date.push(starTime, endTime);
      const [start, end] = val;
      this.startTimeshow = false;
      this.dateValue = `${this.formatDate(start)} - ${this.formatDate(end)}`;
      this.onLoad(1);
    },
    formatDate(date) {
      return `${date.getYear() + 1900}/${date.getMonth() +
        1}/${date.getDate()}`;
    },
    ////订单类型选择
    clickItem(val) {
      this.currentTypeCopy = val;
    },
    selectType() {
      this.orderShow = true;
      this.currentTypeCopy = JSON.parse(JSON.stringify(this.currentType));
    },
    // 订单选择确定
    submitOrder() {
      this.orderShow = false;
      this.currentType = this.currentTypeCopy;
      sessionStorage.setItem(
        "homeOrderType",
        JSON.stringify(this.currentTypeCopy)
      );
      this.onLoad(1);
    },
    // 跳转
    jump(val) {
      console.log(val);
      if (val === "2") {
        let titleText = "安卓";
        this.$store.commit("document_title", titleText);
      } else {
        let titleText = "苹果";
        this.$store.commit("document_title", titleText);
      }
      sessionStorage.setItem("applyInfo", "");
      this.$router.push({ path: "/RightApply/addApply", query: { type: val } });
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  .box {
    margin: 40px 30px;
    .home_title {
      font-family: PingFang SC;
      font-weight: 800;
      color: #333333;
    }
    .tab_bar {
      .item {
        //height: 190px;
      }
      img {
        width: calc(50vw - 45px);
        height: calc((50vw - 45px) * 27 / 47);
      }
    }
    .input_btn {
      margin: 20px auto 0;
      width: calc(100vw - 50px);
      height: 80px;
      border-radius: 40px;
      ::v-deep .van-search {
        padding: 0;
      }
      /deep/ .van-search__content {
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .img_seach {
        img {
          width: 40px;
          margin-right: 15px;
        }
      }
    }
    .order_filter {
      margin: 50px auto;
      width: 100%;
      .selec_ {
        height: 35px;
        img {
          width: 32px;
          height: 32px;
        }
      }
    }
    .Home_order {
      padding-bottom: 50px;
      .tit_top {
        margin-top: 70px;
        width: calc(100vw - 60px);
        height: 40px;
        background: linear-gradient(90deg, #e4ebf9 0%, #ffffff 78%);
        border-radius: 20px;
        .date {
          font-size: 66px;
          font-family: DIN;
          font-weight: bold;
          color: #434552;
          margin-bottom: 30px;
        }
        .mouth {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
        }
        .right {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
      .orderItem {
        margin-top: 30px;
        width: calc(100vw - 60px);
        //height: 520px;
        padding-bottom: 10px;
        background: #ffffff;
        box-shadow: 0px 4px 21px 9px rgba(196, 197, 197, 0.4);
        border-radius: 20px;
        .bot_img {
          width: calc(100vw - 80px);
          margin: 20px auto 0;
          height: 150px;
          background: #f6f8fe;
          border-radius: 16px;
          .img_left {
            margin-left: 20px;
            width: 126px;
            height: 130px;
            img {
              margin-top: 10px;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .img_right {
            height: 27px;
            color: #333333;
          }
        }
        .left_wid {
          width: 150px;
          white-space: nowrap;
        }
        .item_top {
          height: 70px;
          width: 100%;
          border-bottom: 1px solid #f9f9f9;
          .item_tit_le {
            width: 180px;
            height: 72px;
            line-height: 72px;
          }
          .item_tit_ri {
            width: 148px;
            height: 72px;
            background: linear-gradient(
              -90deg,
              #e1f0fe 0%,
              rgba(225, 240, 254, 0) 100%
            );
            border-radius: 0px 20px 0px 0px;
            border-radius: 0px 20px 0px 0px;
            color: #0080ff;
            text-align: center;
            line-height: 72px;
          }
        }
      }
    }
    .Home_order_No {
      width: 100%;
      min-height: 500px;
      .order_No {
        width: 134px;
        text-align: center;
        img {
          width: 134px;
          height: 174px;
        }
        .text {
          color: #666;
        }
      }
    }
  }
  .order_title {
    text-align: center;
    width: 100vw;
    margin: 50px auto;
  }
  .line {
    width: 100vw;
    height: 1px;
    background-color: #eee;
  }
  .order_box {
    margin-top: 50px;
    .berfore {
      background: #ffffff;
      border: 1px solid #cccccc;
      color: #999999;
    }
    .after {
      background: #cddef4;
      border: 1px solid #577bb5;
      color: #577bb5;
    }
    .item {
      width: 175px;
      line-height: 68px;
      height: 68px;
      text-align: center;
      border-radius: 34px;
      margin: 0 20px;
    }
  }
  .order_btn {
    width: 100%;
    height: 350px;
    .btn {
      text-align: center;
      line-height: 88px;
      width: 300px;
      height: 88px;
      border-radius: 44px;
    }
    .cancel_b {
      background: #f0f0f0;
      box-shadow: 0px 6px 15px 1px rgba(149, 149, 150, 0.2);
      color: #333333;
    }
    .submit_b {
      background: #577bb5;
      box-shadow: 0px 6px 15px 1px rgba(87, 123, 181, 0.2);
      color: #ffffff;
    }
  }
}
.search-btn {
  background-color: @base-color;
  height: 68px;
  border-radius: 34px;
  color: #fff;
  padding: 0 30px;
}
</style>
